import React, { Component } from 'react';
import Section from '../components/component.section';
import { NavLink,withRouter } from "react-router-dom";
import Global from '../globals/global';
//if (typeof window === "undefined")  var window={}
const $ = window.$;

class Contact extends Global {

    constructor(props) {
        super(props);

        this.send = this.send.bind(this);
        this.validate = this.validate.bind(this);
        this.validateEmail = this.validateEmail.bind(this);

        this.state = {
            name: null,
            email: null,
            phone: null,
            message: null,
            result: null,
            sending: false,
            success: null,
            errors: [],
        };
    }

    changeinputform(event){
       this.changeinput(event);

       var options ={
           callback:this.validate.bind(this),
           clear:true
       }
          
         this.delay(options)
       
    }

    componentDidMount() {
        
        this.validate();
    }

    componentDidUpdate(prevProps, prevState) {

       /* if (prevState.name !== this.state.name ||
            prevState.email !== this.state.email ||
            prevState.phone !== this.state.phone ||
            prevState.message !== this.state.message) {

            this.validate();
           
        }*/
    }

    send() {
       // this.config().email = 'felipe@mobilesail.com';

        this.config().mailinfo.RecipientEmail = this.config().email;

        if (this.validate()) {
            this.setState({ sending: true, });
   var options = {
                arraydata: 'result',
                method: 'ContactUs',
                query: {
                    Name: this.state.name,
                    Email: this.state.email,
                    Message: this.state.message,
                    mailinfo: this.config().mailinfo
                },
                callback: function (data, result) {
                    this.cleanForm();
                    this.setState({
                        success: this.t('Message sent successfully.'),
                        sending: false,
                    });

                     //google analytics event
                    this.ga().event({
                          category: 'Contact',
                          action: 'Sent message',
                          label: 'Sent message'
                    });

                }.bind(this)
            };
            this.all(options);            
        }
    }

    validate() {
        let errors = [];
        if (this.state.name === null || this.state.name.length < 1) {
            errors.push(this.t('Enter your name'));
        }
        if (!this.validateEmail(this.state.email)) {
            errors.push(this.t('Enter a valid email'));
        }
        if (this.state.message === null || this.state.message.length < 21) {
            errors.push(this.t('Enter your Message - More than 20 characters'));
        }
        this.setState({ errors: errors });
        return errors.length === 0;
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    /**
     * @fucntion cleanForm
     **/
    cleanForm(){
       
        $('#name').val('');
        $('#email_contact').val('');
        $('#message_contact').val('');
        this.setState({
            name: null,
            email: null,
            phone: null,
            message: null,
            result: null,
            sending: false,
            success: null,
            errors: [],
        });
    }

    render() {
        return (
            <div>

                <Section origin='contact' />
                {/* End section */}

                <main>
                    <div id="position">
                        <div className="container">
                            <ul>
                                <li><NavLink to="/tours/1">{this.t("Tours")}</NavLink></li>
                                <li>{this.t('Contact')}</li>
                            </ul>
                        </div>
                    </div>
                    {/* End Position */}

                    <div className="container margin_60">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form_title">
                                    <h3><strong><i className="icon-pencil"></i></strong>{this.t('Contact Us!')}</h3>
                                    <p>{this.t('Be in Touch')}</p>
                                    
                                </div>
                                <div className="step">

                                    <div id="message-contact"></div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>{this.t('Name')}</label>
                                                <input type="text" className="form-control" id="name" name="name" placeholder={this.t("Enter Name")}
                                                    onChange={this.changeinputform.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* End row */}
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>{this.t('Email')}</label>
                                                <input type="email" id="email_contact"  name="email" className="form-control" placeholder={this.t('"Enter Email"')}
                                                    onChange={this.changeinputform.bind(this)} />
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Phone</label>
                                                <input type="text" id="phone_contact" name="phone_contact" className="form-control" placeholder="Enter Phone number"
                                                    onChange={event => { this.setState({ phone: event.target.value }) }} />
                                            </div>
                                        </div>  */}
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>{this.t('Message')}</label>
                                                <textarea rows="5" id="message_contact" name="message" className="form-control" placeholder={this.t("Write your message")} style={{ height: '200px' }}
                                                    onChange={this.changeinputform.bind(this)} ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {
                                                (this.state.errors !== null && this.state.errors.length > 0) ? (
                                                    <div className="errors">
                                                        {
                                                            this.state.errors.map((error, index) => {
                                                                return (
                                                                    <div key={`icon-exclamation-${index}`}>
                                                                        <i className="icon-exclamation"></i> <span>{error}</span><br />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                ) : null
                                            }
                                            {this.state.success !== null ? <label>{this.state.success}</label> : null}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {this.state.sending ? <div>{this.t('Sending')}...</div> : <input type="button" value={this.t("Submit")} className="btn_1" id="submit-contact" onClick={this.send} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End col-md-8 */}

                            <div className="col-md-4">
                                <div className="box_style_1">
                                    <span className="tape">
                                    </span>


                                    <b>{this.t('Our Vacation Concierge is available Everyday from 9:00am to 6:00pm Central Time, to answer your questions.')}</b>

                                    

                                    <hr />
                                    {console.log('process.env.REACT_APP_BOOKINGWIDGET',process.env)}
                                    {process.env.REACT_APP_BOOKING_WIDGET != "bookingwidget2" && this.config().phone != ''?
                                    <h6 className="title_cont price_ph">{this.t('USA / CANADA')} <span><i className="icon-pin pull-right"></i></span></h6>
                                    :''}

                                    {process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget2"?
                                    <span>
                                    {this.config().mexicophone?
                                        <h6 className="title_cont price_ph">{this.config().phone != ''? <span>{this.t('USA / CANADA')} <i className="icon-pin pull-right"></i></span>:''}</h6>
                                        :<h6 className="title_cont"> {this.t('Phone')} <span><i className="icon-pin pull-right"></i></span></h6>
                                    }
                                    </span>
                                    :''}

                                   {this.config().phone != ''?
                                    <p className="price_ph">{this.config().phone} ({this.t('Toll Free')})</p>
                                    :''}
                                    <hr className="price_ph" />


                                    {/*process.env.REACT_APP_BOOKING_WIDGET == "bookingwidget2"?
                                    <span>
                                    {this.config().mexicophone?<div>
                                    <h6 className="title_cont">{ MÉXICO <span><i className="icon-pin pull-right"></i></span></h6>
                                    <p>{this.config().mexicophone}</p>
                                    </div>:''}
                                   
                                    </span>
                                    :'' */}
                                     
                            
                                    <span>
                                    <h6 className="title_cont">{/* this.t('CANCUN / COZUMEL PUERTO VALLARTA / LOS CABOS') */} MÉXICO <span><i className="icon-pin pull-right"></i></span></h6>
                                    {/* this.config().mexicophone?<p>{this.config().mexicophone}</p>:<p>+52 (322) 209 0983</p> */}

                                    <p><b>Puerto Vallarta </b>+52 322 209 0983 </p>
<p><b>Cancún & Riviera Maya</b> +52 998 193 0560</p>
<p><b>Los Cabos</b> +52 624 142 2636</p>

                                    <hr />

                                    {/*
                                    <h6 className="title_cont price_ph">{this.t('DOMINICAN REPUBLIC')}: <br />{this.t('PUNTA CANA')}<span><i className="icon-pin pull-right"></i></span></h6>
                                    <p className="price_ph">(809) 552 0900</p>
                                    <hr className="price_ph" />
                                    <h6 className="title_cont price_ph">{this.t('PUERTO PLATA')}<span><i className="icon-pin pull-right"></i></span></h6>
                                    <p className="price_ph">(809) 586 3497</p>*/}

                                    </span>
                             
                                     

                                    <span>{this.t('For inquiries please email us at')} <a href={"mailto:"+this.config().email}>{this.config().email}</a> <br />{this.t('or simply fill out the online contact form')}.</span>

                                </div>
                            </div>
                            {/* End col-md-4 */}
                        </div>
                        {/* End row */}
                    </div>
                    {/* End container */}

                    <div id="overlay"></div>
                    {/* Mask on input focus */}

                </main>
                {/* End main */}
            </div>
        );
    }
}

export default Contact